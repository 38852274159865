import React from "react";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Tag from "../components/blog/tag";

import { TAGS } from "../constants";

const TagsWrapper = styled.div`
  display: flex;
  font-family: Montserrat, Roboto, "Helvetica Neue";
  height: 100%;
  overflow: scroll;
  padding: 5em;
  flex-direction: column;
`;

const styles = () => ({
  listText: {
    marginLeft: "0.5rem"
  }
});

const TagsPage = props => {
  const { classes, data } = props;
  const { group } = data.allMarkdownRemark;
  return (
    <Layout>
      <SEO title="Xabadu" keywords={[`gatsby`, `application`, `react`]} />
      <TagsWrapper>
        <h1>Tags</h1>
        <List className={classes.list}>
          {group.map(tag => (
            <ListItem
              key={tag.fieldValue}
              className={classes.listItem}
              alignItems="flex-start"
            >
              <Tag mode="compact" type={tag.fieldValue} />
              <Link
                to={`/tags/${kebabCase(tag.fieldValue)}/`}
                className="button-link"
              >
                <ListItemText
                  className={classes.listText}
                  primary={TAGS[tag.fieldValue].label}
                  secondary={`${tag.totalCount} ${
                    tag.totalCount > 1 ? "posts" : "post"
                  }`}
                />
              </Link>
            </ListItem>
          ))}
        </List>
      </TagsWrapper>
    </Layout>
  );
};

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired
        }).isRequired
      )
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    })
  })
};

export default withStyles(styles)(TagsPage);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
